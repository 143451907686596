<style scoped>
@media (max-width: 1024px) {
  .c-sm-force-text-left {
    text-align: left !important;
  }
}
.c-billing-overview {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  props: ['onlyCore'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    HalfCircleSpinner
  },
  validations: {
    billingAddress: {
      firstName: {required},
      lastName: {required},
      line1: {required},
      line2: {},
      country: {required},
      region: {required},
      city: {required},
      zip: {required}
    },
  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
    ])
  },
  methods: {
    async retrieveBillingAddress() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/billing-address`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.billingAddress.firstName = data.address.first_name;
          this.billingAddress.lastName = data.address.last_name;
          this.billingAddress.line1 = data.address.line1;
          this.billingAddress.line2 = data.address.line2
          this.billingAddress.country = data.address.country;
          this.billingAddress.region = data.address.region;
          this.billingAddress.city = data.address.city;
          this.billingAddress.zip = data.address.zip;

          // this.billingAddressLocked = data.locked;

          if(!this.billingAddressLocked) {
            this.billingAddress.country = this.billingAddress.country || data.prefill.country;
            this.billingAddress.region = this.billingAddress.region || data.prefill.region;
          }
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async retrieveTaxInfo() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/tax-info`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.taxInfo.rate = data.tax.amount;
          this.taxInfo.abbreviation = data.tax.abbreviation;

        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async retrieveBillingOverview() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/billing-overview`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.billingStats.estimate = data.estimate;
          this.billingStats.nextBill = data.next_bill;

        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async saveBillingAddress() {
      this.$v.billingAddress.$touch();
      if(this.$v.billingAddress.$error) return;
      this.savingBillingAddress = true;
      this.billingAddressLocked = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          first_name: this.billingAddress.firstName,
          last_name: this.billingAddress.lastName,
          line1: this.billingAddress.line1,
          line2: this.billingAddress.line2,
          country: this.billingAddress.country,
          region: this.billingAddress.region,
          city: this.billingAddress.city,
          zip: this.billingAddress.zip
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/billing-address`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(!response.ok) {
          if(response.status === 400) {
            let data = await response.json();
            switch(data.error) {
              case 'address-invalid': {
                this.$swal({
                  icon: 'error',
                  text: this.$t('billing.overview.address.save.error'),
                  title: 'This seems like an invalid address'
                });
                break;
              }
              case 'geocode-error': {
                this.$swal({
                  icon: 'error',
                  text: this.$t('billing.overview.address.save.error'),
                  title: 'This seems like an invalid address'
                });
                break;
              }
              case 'geocode-down': {
                this.$swal({
                  icon: 'error',
                  text: this.$t('billing.overview.address.save.error'),
                  title: 'We are having trouble contacting our billing system, please try again'
                });
                break;
              }
              default: {
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            }
            this.savingBillingAddress = false;
            this.billingAddressLocked = false;
            return;
          }
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.savingBillingAddress = false;
        this.billingAddressLocked = false;

        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('billing.overview.address.save.error')
        });
      }
    },
    async processAddressUpdated(data) {
      await this.retrieveBillingAddress();
      this.savingBillingAddress = false;
      if(data.status) {
        this.billingAddressLocked = true;
        this.$swal({
          icon: 'success',
          text: this.$t('billing.overview.address.save.success')
        });
      } else {
        switch(data.error) {
          case 'address-invalid': {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.overview.address.save.error'),
              title: 'This seems like an invalid address'
            });
            break;
          }
          case 'geocode-error': {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.overview.address.save.error'),
              title: 'This seems like an invalid address'
            });
            break;
          }
          case 'geocode-down': {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.overview.address.save.error'),
              title: 'We are having trouble contacting our billing system, please try again'
            });
            break;
          }
          default: {
            this.$swal({
              icon: 'error',
              title: this.$t('billing.overview.address.save.error')
            });
          }
        }
      }
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:address:updated', this.processAddressUpdated);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.retrieveBillingAddress();
    this.ready = true;
    await this.retrieveTaxInfo();
    if(!this.onlyCore) {
      await this.retrieveBillingOverview();
    }
  },
  destroyed() {
    try {
      this.$socket.client.off('billing:address:updated', this.processAddressUpdated);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      ready: false,
      error: false,
      billingStats: {
        estimate: null,
        nextBill: null
      },
      billingAddress: {
        firstName: null,
        lastName: null,
        line1: null,
        line2: null,
        country: null,
        region: null,
        city: null,
        zip: null
      },
      taxInfo: {
        rate: null,
        abbreviation: null
      },
      billingAddressLocked: false,
      savingBillingAddress: false,
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row justify-content-center c-billing-overview">
        <div class="col-lg-9 col-sm-12">
          <div class="card card-body shadow shadow-lg">
            <div class="row">
              <div class="col-lg-4 col-sm-12 text-left c-sm-force-text-left">
                <h4 class="text-uppercase">
                  {{$t('billing.overview.title')}}
                </h4>
                <div>
                  <h5 class="text-muted">
                    <span>
                      <CopyableText :text="getAccountName()"/>
                    </span>
                    -
                    <span>
                      <CopyableText :text="getAccountId()"/>
                    </span>
                  </h5>
                </div>
              </div>
              <div class="col-lg-4 col-sm-12 text-center c-sm-force-text-left" v-if="billingStats.estimate !== null">
                <h4 class="text-uppercase">
                  {{$t('billing.overview.estimate')}}
                </h4>
                <h2>{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(billingStats.estimate)}}</h2>
              </div>
              <div class="col-lg-4 col-sm-12 text-right c-sm-force-text-left" v-if="billingStats.nextBill !== null">
                <h4 class="text-uppercase">
                  {{$t('billing.overview.next_bill')}}
                </h4>
                <h2>
                  {{ $d(parseDate(billingStats.nextBill), 'datetime', getDTLocale()) }}
                </h2>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col">
                <div>
                  <h4 class="text-uppercase d-inline-block">
                    {{$t('billing.overview.address.title')}}
                  </h4>
                  <button
                      class="btn btn-outline-success btn-xs btn-sm ml-3 mt-n2 d-inline-block"
                      v-if="savingBillingAddress || !billingAddressLocked"
                      :disabled="savingBillingAddress"
                      :class="{'disabled': savingBillingAddress}"
                      v-on:click="saveBillingAddress()"
                  >
                    <half-circle-spinner
                        v-if="savingBillingAddress"
                        :animation-duration="900"
                        :size="18"
                        class="align-middle d-inline-block"
                    />
                    {{$t('billing.overview.address.save.button')}}
                  </button>
                </div>
                <template v-if="billingAddressLocked && !savingBillingAddress">
                  <div class="text-center">
                    <div class="alert alert-warning">
                      <h4 class="text-black mb-0">
                        <i class="far fa-info-circle"/>
                        {{$t('billing.overview.address.locked.message')}}
                      </h4>
                    </div>
                  </div>
                </template>
                <!-- Personal -->
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <label>{{$t('billing.payment.info.first_name')}}</label>
                    <input
                        class="form-control"
                        v-model="billingAddress.firstName"
                        :class="{ 'is-invalid': $v.billingAddress.firstName.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        autocorrect="off"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <label>{{$t('billing.payment.info.last_name')}}</label>
                    <input
                        class="form-control"
                        v-model="billingAddress.lastName"
                        :class="{ 'is-invalid': $v.billingAddress.lastName.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        autocorrect="off"
                    />
                  </div>
                </div>
                <!-- Address -->
                <div class="row mt-2">
                  <div class="col-lg-12 col-sm-12">
                    <label>{{$t('billing.payment.info.line1')}}</label>
                    <input
                        class="form-control"
                        v-model="billingAddress.line1"
                        :placeholder="$t('billing.payment.info.line1_placeholder')"
                        :class="{ 'is-invalid': $v.billingAddress.line1.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        autocorrect="off"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12 col-sm-12">
                    <label>{{$t('billing.payment.info.line2')}} (optional)</label>
                    <input
                        class="form-control"
                        v-model="billingAddress.line2"
                        :class="{ 'is-invalid': $v.billingAddress.line2.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        autocorrect="off"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6 col-sm-12">
                    <label>{{$t('billing.payment.info.city')}}</label>
                    <input
                        class="form-control"
                        v-model="billingAddress.city"
                        :class="{ 'is-invalid': $v.billingAddress.city.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        autocorrect="off"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <label>{{$t('billing.payment.info.zip')}}</label>
                    <input
                        class="form-control"
                        v-model="billingAddress.zip"
                        :class="{ 'is-invalid': $v.billingAddress.zip.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        autocorrect="off"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6 col-sm-12">
                    <span>{{ $t('billing.payment.info.country') }}</span>
                    <!-- :topCountry="billingAddress.country" -->
                    <country-select
                        className="custom-select"
                        :class="{'is-invalid': $v.billingAddress.country.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        :placeholder="$t('billing.payment.info.country_prompt')"
                        v-model="billingAddress.country"
                        :country="billingAddress.country"
                        :usei18n=false
                        :removePlaceholder=false
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <span>{{ $t('billing.payment.info.region') }}</span>
                    <region-select
                        className="custom-select"
                        :class="{'is-invalid': $v.billingAddress.region.$error, 'disabled': billingAddressLocked }"
                        :disabled="billingAddressLocked"
                        :placeholder="$t('billing.payment.info.region_prompt')"
                        v-model="billingAddress.region"
                        :country="billingAddress.country"
                        :region="billingAddress.region"
                        :usei18n=false
                    />
                  </div>
                </div>
              </div>
            </div>
            <template v-if="taxInfo.rate">
              <hr>
              <div class="row mt-2">
                <div class="col">
                  <div>
                    <h4 class="text-uppercase d-inline-block">
                      {{$t('billing.overview.tax.title')}}
                    </h4>
                    <h3>
                      <b>{{ taxInfo.rate }}</b> % {{ taxInfo.abbreviation }}
                      <small class="text-muted">{{$t('billing.overview.tax.rate')}}</small>
                    </h3>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
